(function(){
    
	//INCLUDES METHOD FOR STRINGS
    if(!String.prototype.includes){
        String.prototype.includes = function (str) {
            var returnValue = false;

            if (this.indexOf(str) !== -1) {
                returnValue = true;
            }

            return returnValue;
        }
    }
	
	// DEFINE CONSOLE WARN METHOD
	if (typeof console.warn === 'undefined') {
		console.warn = function () { };
	}
	
	// DEFINE CONSOLE LOG METHOD
	if (typeof console.log === 'undefined') {
		console.log = function () { };
	}

	// DEFINE CONSOLE INFO METHOD
	if (typeof console.info === 'undefined') {
		console.info = function () { };
	}

	// DEFINE CONSOLE ERROR METHOD
	if (typeof console.error === 'undefined') {
		console.error = function () { };
	}

})();