$(document)
		.ready(
				function() {
					if ($(".g-SeminarFilter").length != 0) {
						var colToRowSection = $(".g-SeminarFilter__colToRow.js-colToRow");
						var rowSelectSection = $(".g-SeminarFilter__rowSelect.js-rowSelect");
						var buttonSection = $(".g-SeminarFilter__combination.js-combination");
						var isMobile = false || $(window).width() < 641;
						var noResult = $(".g-SeminarFilter__noResult");

						window.addEventListener("orientationchange",
								function() {
									location.reload();
								});

						noResult.hide();

						$(document)
								.on(
										"change",
										".g-SeminarFilter select",
										function() {
											var option = $(this).find(
													"option:selected").val();
											var otherSelectBoxOption = $(
													".g-SeminarFilter select")
													.not(this).find(
															"option:selected")
													.val();

											if (option == "default"
													&& otherSelectBoxOption == "default") {
												revertToDefault(isMobile);
												return;
											} else if (option != "default"
													&& otherSelectBoxOption != "default") {
												var selectedOptions = {
													"row" : $(this).hasClass(
															"js-rowSelect") ? option
															: otherSelectBoxOption,
													"col" : $(this).hasClass(
															"js-colToRow") ? option
															: otherSelectBoxOption
												}
												buttonView(selectedOptions,
														buttonSection,
														rowSelectSection,
														colToRowSection,
														isMobile);
												return;
											} else if (option != "default"
													&& otherSelectBoxOption == "default") {

												if ($(this).hasClass(
														"js-rowSelect")) {
													selectRow(option,
															rowSelectSection,
															colToRowSection,
															buttonSection,
															isMobile);
												} else if ($(this).hasClass(
														"js-colToRow")) {
													columnToRow(option,
															colToRowSection,
															rowSelectSection,
															buttonSection,
															isMobile);
												}
											}

											else if (option == "default"
													&& otherSelectBoxOption != "default") {
												if ($(this).hasClass(
														"js-rowSelect")) {
													columnToRow(
															otherSelectBoxOption,
															colToRowSection,
															rowSelectSection,
															buttonSection,
															isMobile);
												} else if ($(this).hasClass(
														"js-colToRow")) {
													selectRow(
															otherSelectBoxOption,
															rowSelectSection,
															colToRowSection,
															buttonSection,
															isMobile);
												}
											}
										});

						var revertToDefault = function(isMobile) {

							if (isMobile) {
								colToRowSection.hide();
								buttonSection.hide();
								rowSelectSection.hide();
							} else {
								colToRowSection.hide();
								buttonSection.hide();
								rowSelectSection.show();
							}

							colToRowSection.find(".g-SeminarFilter__dataRow")
									.show();
							colToRowSection
									.find(".g-SeminarFilter__headingRow")
									.show();
							rowSelectSection.find(".g-SeminarFilter__dataRow")
									.show();
							rowSelectSection.find(
									".g-SeminarFilter__headingRow").show();
							noResult.hide();
						}

						var columnToRow = function(selectedOption,
								currentFilterType, otherFilterType1,
								otherFilterType2, isMobile) {
							currentFilterType.find(".g-SeminarFilter__dataRow")
									.hide();
							currentFilterType.find(
									".g-SeminarFilter__dataRow[data-row-category='"
											+ selectedOption + "']").show();
							otherFilterType1.hide();
							otherFilterType2.hide();
							if (isMobile) {
								currentFilterType.find(
										".g-SeminarFilter__headingRow").hide();
							}
							currentFilterType.show();
							noResult.hide();
						}

						var selectRow = function(selectedOption,
								currentFilterType, otherFilterType1,
								otherFilterType2, isMobile) {
							currentFilterType.find(".g-SeminarFilter__dataRow")
									.hide();
							currentFilterType.find(
									".g-SeminarFilter__dataRow[data-row-category='"
											+ selectedOption + "']").show();
							otherFilterType1.hide();
							otherFilterType2.hide();
							if (isMobile) {
								currentFilterType.find(
										".g-SeminarFilter__headingRow").hide();
							}
							currentFilterType.show();
							noResult.hide();
						}

						var buttonView = function(selectedOptionsObj,
								currentFilterType, otherFilterType1,
								otherFilterType2, isMobile) {

							currentFilterType.find(
									".g-SeminarFilter__combination__listItem")
									.hide();
							var rowToDisplay = currentFilterType
									.find(".g-SeminarFilter__combination__listItem[data-row-category='"
											+ selectedOptionsObj.row
											+ "'][data-col-category='"
											+ selectedOptionsObj.col + "']");

							rowToDisplay.show();
							otherFilterType1.hide();
							otherFilterType2.hide();
							currentFilterType.show();

							if (rowToDisplay
									.find(".g-SeminarFilter__combination__link").length == 0) {
								noResult.show();
							} else {
								noResult.hide();
							}
						}
					}
				});