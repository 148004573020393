$("document").ready(function () {
  $(".product_results .g-ProductListingListUnit").addClass("productListClass");
  $(".product_results2 .g-ProductListingListUnit").addClass("productListClass");

  $(".disc-cont span div p span").append("<br/>");

  $("#btn-back").addClass("inactive");
  $(".g-ProductRecommendation .g-RecommendationTabBtn .tab1").click(function (ev) {
    ev.preventDefault();
    var prnt = $(this).parent();

    if (!prnt.hasClass('active')) {
      var cls = $(this).attr("class");
      $(".g-ProductRecommendation .filter-close").fadeIn();
      $(".g-ProductRecommendation .g-RecommendationTabBtn li").removeClass('active');
      if ($(".g-ProductRecommendation [id*=filter-tab]").is(":visible")) {
        $(".g-ProductRecommendation [id*=filter-tab]:visible").slideUp();
        $(".g-ProductRecommendation #filter-" + cls).slideDown();
      }
      else { $(".g-ProductRecommendation #filter-" + cls).slideDown(); }
      if (!prnt.hasClass('lastActive')) {
        $(".g-ProductRecommendation .filter-qa-cont input").attr("checked", false);
        var fn_filterProduct = "filter" + $(this).attr("class");
        window[fn_filterProduct]();
      }
      $(".g-ProductRecommendation .g-RecommendationTabBtn li").removeClass("lastActive");
      prnt.addClass("lastActive");
      if (cls == "tab1") {
        $(".g-ProductRecommendation .qa-sec").hide();
        $(".g-ProductRecommendation .qa-sec:first-child").show().addClass('active');
        $("#btn-back").addClass("inactive");
        $("#btn-next").removeClass('inactive');
      }
    }
    else {
      $(".g-ProductRecommendation .g-RecommendationTabBtn a").removeClass("lastActive");
      $("[id*=filter-tab]:visible").slideUp();
      $(".g-ProductRecommendation .filter-close").fadeOut();
    }
    prnt.toggleClass('active');
  });
  $("body").on("click", ".filter-close", function (ev) {
    $("[id*=filter-tab]:visible").slideUp();
    $(".g-ProductRecommendation .g-RecommendationTabBtn li").removeClass('active');
    $(".g-ProductRecommendation .filter-close").fadeOut();
  });
  $("body").on("click", ".g-ProductRecommendation .filter-qa-cont input[type=checkbox],.g-ProductRecommendation .filter-qa-cont input[type=radio]", function (ev) {
    var getActiveTab;
    if ($(this).closest('div').hasClass('qa-sec')) {
      getActiveTab = $(this).closest('div').parent('div').attr('id').replace('-', '');
    }
    else {
      getActiveTab = $(this).closest('div').attr('id').replace('-', '');
    }
  });
  $("body").on("click", "#btn-back", function (ev) {
    ev.preventDefault();
    $(".qa-sec:visible").stop(true, true);
    if (!$(this).hasClass('inactive')) {
      $("#btn-next").removeClass('inactive');
      if ($(".qa-sec:visible").prev().length > 0) {
        $(".qa-sec:visible").slideUp().prev().slideDown();
        if ($(".qa-sec:visible").prev().prev().length == '0') {
          $(this).addClass('inactive');
        }
        $(this).parents('#btn-cont').siblings(".disc-cont").find("span:visible").parents("span").hide().prev().show();
      }
    }
  });
  $("body").on("click", "#btn-back", function (ev) {
    ev.preventDefault();
    $('.thankyoumsg').hide();
    $('.alertmsg').hide();
    $(".qa-sec").removeClass("questionsupport");
    $("#btn-next").removeClass('hideBtn');
    $('#btn-back-support').remove();
    $("#finishBtn").addClass("hideBtn");
    $('#finishBtn').removeClass('finishBtnHide');
    $('#finishBtn').removeClass("finishBtn-hide");
    $('#finishBtn').removeClass("finishBtn-disabled");
    $('#finishBtn').removeClass('inactive');
    $(".productListClass").removeClass("hideDuplicate");
    $(".qa-sec").removeClass("questionHide");
    $('.g-Recommendation__numOfProd .filter-header-res').fadeOut();
    $(".g-Recommendation__discCont span div p span").removeClass("questionHide");
    $(".product_results .productListClass, .product_results2 .productListClass").show();
    $(".filter-header-res .g-Text .cmn-richtext span").html($(".filter-header-res .g-Text .cmn-richtext span").html().replace('4', '#'));
  });
  $("body").on("click", "#btn-back-support", function (ev) {
    ev.preventDefault();
    $('.thankyoumsg').hide();
    $('.alertmsg').hide();
    $('#btn-back-support').remove();
    $('#btn-back').show();
    $('#finishBtn').removeClass("finishBtn-hide");
    $('#finishBtn').removeClass("finishBtnHide");
    $('#finishBtn').removeClass('inactive');
    $(".productListClass").removeClass("hideDuplicate");
    $(".qa-sec").removeClass("questionHide");
    $('.g-Recommendation__numOfProd .filter-header-res').fadeOut();
    $(".g-Recommendation__discCont span div p span").removeClass("questionHide");
    $(".product_results .productListClass, .product_results2 .productListClass").show();
    $(".filter-header-res .g-Text .cmn-richtext span").html($(".filter-header-res .g-Text .cmn-richtext span").html().replace('4', '#'));
  });
  $("body").on("click", "#btn-next", function (ev) {
    ev.preventDefault();
    $(".qa-sec:visible").stop(true, true);
    if (!$(this).hasClass('inactive')) {
      $("#btn-back").removeClass('inactive');
      if ($(".qa-sec:visible").next().length > 0) {
        $(".qa-sec:visible").slideUp().next().slideDown();
        if ($(".qa-sec:visible").next().next().next().next().next().next().length == '0') {
          $(this).addClass('inactive');
          $(".inactive").addClass(' hideBtn');
          $("#finishBtn").removeClass('hideBtn');
          $('#finishBtn').removeClass('finishBtnHide');
          $('#finishBtn').removeClass("finishBtn-hide");
          $('#finishBtn').removeClass("finishBtn-disabled");
          $(".productListClass").removeClass("hideDuplicate");
          $('.thankyoumsg').fadeOut(300);
          $('.alertmsg').fadeOut(300);
        }
        $(this).parents('#btn-cont').siblings(".disc-cont").find("span:visible").parents("span").hide().next().show();
      }
    }
  });
  $("body").on("click", "#btn-resetall", function (ev) {
    ev.preventDefault();
    filtertab1();
    $('.thankyoumsg').hide();
    $('.alertmsg').hide();
    $('#btn-back').show();
    $("#btn-back").addClass("inactive");
    $('#btn-back-support').remove();
    $("#btn-next").removeClass('inactive');
    $("#btn-next").removeClass('hideBtn');
    $("#finishBtn").addClass("hideBtn");
    $('#finishBtn').removeClass('finishBtnHide');
    $('#finishBtn').removeClass("finishBtn-hide");
    $('#finishBtn').removeClass("finishBtn-disabled");
    $('#finishBtn').removeClass('inactive');
    $(".g-Recommendation__discCont span div p span").removeClass("questionHide");
    $(".productListClass").removeClass("hideDuplicate");
    $(this).parent().siblings(".qa-sec").find("input").attr("checked", false);
    $(".qa-sec").removeClass("questionHide");
    $(".qa-sec").slideUp();
    $(".qa-sec:first-child").slideDown();
    $(this).parents('#btn-cont').siblings(".disc-cont").find("span:visible").parents("span").hide();
    $(".disc-cont").find("span:first-child").parents("span:lt(1)").show();
    $('.g-Recommendation__numOfProd .filter-header-res').fadeOut();
    $(".product_results .productListClass, .product_results2 .productListClass").show();
    $(".filter-header-res .g-Text .cmn-richtext span").html($(".filter-header-res .g-Text .cmn-richtext span").html().replace('4', '#'));
    if ($('.qa-sec').length == 1) {
      $("#btn-next").addClass("hideBtn");
      $('#finishBtn').removeClass("hideBtn");
    }
    filtertab1();
  });

  $('#finishBtn').click(function (ev) {
    ev.preventDefault();

    $(".g-Recommendation__qa-sec.qa-sec").last().addClass("questionHide");
    $(".g-Recommendation__discCont span").last("div p").addClass("questionHide");
    $('#btn-back').hide();

    if ($("#finishBtn").hasClass("finishBtnHide")) {
      $(this).text();
    } else {
      $('#btn-back').after("<a class='g-ButtonUnit__link l-ButtonUnit__link' id='btn-back-support' link='#'>" + $('#btn-back').html() + '</div>');
    }

    function isCheckedById() {
      var checked = $(".g-ProductRecommendation #filter-tab1 input:checked").length;

      if (checked == '0') {
        $('#finishBtn').addClass('finishBtnHide');
        $('#finishBtn').addClass('inactive');
        $(".qa-sec").removeClass("questionHide");
        $('#btn-back-support').remove();
        $('#btn-back').show();
        $('.thankyoumsg').hide();
        $('.alertmsg').css('display', 'block');
        return false;
      } else {
        $('#finishBtn').addClass('finishBtnHide');
        $('.thankyoumsg').css('display', 'block');
        $('.alertmsg').hide();
        if ($.trim($(".thankyoumsg").html()) == '') {
          $('#finishBtn').addClass("finishBtn-disabled");
        }
        else {
          $('#finishBtn').addClass("finishBtn-hide");
        }
        return true;
      }
    }
    isCheckedById();

    var optID2;
    $(".g-ProductRecommendation .filter-qa-cont div#filter-tab1 li").find("input").each(function (i) {
      if ($(this).is(":checked")) {
        optID2 = $(this).attr('id');
      }
    });
    function remove_duplicate() {
      var final_data_ranking = [];
      var prev_ranking = 0;
      var prev_weightage = 0;
      var weightage = 0;
      var is_duplicated = false;

      $('.product_results [data-product-qa]').each(function (index) {
        var ranking = $(this).attr("data-ranking");
        var prod_qa = $(this).attr("data-product-qa");
        $.each(prod_qa.split(","), function (index, value) {
          if (optID2 == value.split("=")[0]) {
            weightage = value.split("=")[1];
          }
        });
        if (ranking == prev_ranking) {
          is_duplicated = true;
          if (parseInt(weightage) >= parseInt(prev_weightage)) {
            final_data_ranking[index - 1] = index + 1;
            final_data_ranking[index] = -1;
          }
        }
        if (is_duplicated == false) {
          final_data_ranking[index] = index + 1;
        }
        prev_ranking = ranking;
        prev_weightage = weightage;
        is_duplicated = false;
      });
      return final_data_ranking;
    }
    var rd = remove_duplicate();
    $(".product_results .productListClass").each(function (index) {
      if ($.inArray(index + 1, rd) >= 0) {
        $(this).text();
      } else {
        $(this).addClass('hideDuplicate');
        $(".product_results .productListClass:last-child").removeClass("hideDuplicate");
      }
    });
  });

  $(".g-ProductRecommendation #filter-tab1 input").change(function () {
    $(".productListClass").removeClass("hideDuplicate");
    if ($(".g-ProductRecommendation #filter-tab1 input:checked").length > 0) {
      $('#finishBtn').removeClass('finishBtnHide');
      $('#finishBtn').removeClass('inactive');
      $(".g-Recommendation__discCont span div p span").removeClass("questionHide");
      $('.alertmsg').fadeOut();
      $(".thankyoumsg").fadeOut();
    } else {
      $('#finishBtn').addClass('finishBtnHide');
    }
  });

  var filtertab1 = function () {
    $("#finishBtn").click(function () {
      var filterQA_Arr = [], filterQA_Index = [],
        inputQA = $(".g-ProductRecommendation .filter-qa-cont div#filter-tab1 li input:checked"),
        inputQACnt = inputQA.length;

      if (inputQACnt > 0) {
        $(".g-ProductRecommendation .filter-qa-cont div#filter-tab1 li").find("input").each(function (i) {
          if ($(this).is(":checked")) {
            filterQA_Index.push(i);
            var optID = $(this).attr('id');
            optID = optID.substring(optID.indexOf("-") + 1);
            if ($.inArray(optID, filterQA_Arr) == -1) { filterQA_Arr.push(optID); }
          }
        });
        var showProdCount = 0,
          _qaStartArr = [], _qaStyleArr = [], _qaFinishArr = [],
          _maxStartArr = [], _maxStyleArr = [], _maxFinishArr = [];


        $(".start .productListClass[data-product-qa]").each(function (i) {
          var item = false, pr = $(this).attr("data-product-qa");

          if (pr) {
            _qaStartArr.push(pr);
            if (_qaStartArr[i]) {
              var _toStartArray = _qaStartArr[i].split(',');
              var sumStart = [];
              if (filterQA_Index.length > 1) {
                $.each(filterQA_Index, function (i, val) {
                  var _getmaxVal = Object.keys(_toStartArray).map(function (key) {
                    return _toStartArray[key];
                  });
                  _getmaxVal = _getmaxVal[val].substring(_getmaxVal[val].indexOf("=") + 1);
                  sumStart.push(_getmaxVal);
                });
                var totStart = 0;
                for (var i = 0; i < sumStart.length; i++) {
                  totStart += sumStart[i] << 0;
                }
                _maxStartArr.push(String(totStart));
              }
              else {
                $.each(filterQA_Index, function (i, val) {
                  var _maxVal = Object.keys(_toStartArray).map(function (key) {
                    return _toStartArray[key];
                  });
                  _maxVal = _maxVal[val].substring(_maxVal[val].indexOf("=") + 1);
                  _maxStartArr.push(_maxVal);
                });
              }
            }
          }

        });
        getProdMaxVal("start", _maxStartArr);


        $(".style .productListClass[data-product-qa]").each(function (i) {
          var item = false, pr = $(this).attr("data-product-qa");
          if (pr) {
            _qaStyleArr.push(pr);
            if (_qaStyleArr[i]) {
              var _toStyleArray = _qaStyleArr[i].split(',');
              var sumStyle = [];
              if (filterQA_Index.length > 1) {
                $.each(filterQA_Index, function (i, val) {
                  var _getmaxVal = Object.keys(_toStyleArray).map(function (key) {
                    return _toStyleArray[key];
                  });
                  _getmaxVal = _getmaxVal[val].substring(_getmaxVal[val].indexOf("=") + 1);
                  sumStyle.push(_getmaxVal);
                });
                var totStyle = 0;
                for (var i = 0; i < sumStyle.length; i++) {
                  totStyle += sumStyle[i] << 0;
                }
                _maxStyleArr.push(String(totStyle));
              }
              else {
                $.each(filterQA_Index, function (i, val) {
                  var _maxVal = Object.keys(_toStyleArray).map(function (key) {
                    return _toStyleArray[key];
                  });
                  _maxVal = _maxVal[val].substring(_maxVal[val].indexOf("=") + 1);
                  _maxStyleArr.push(_maxVal);
                });
              }
            }
          }
        });
        getProdMaxVal("style", _maxStyleArr);


        $(".finish .productListClass[data-product-qa]").each(function (i) {
          var item = false, pr = $(this).attr("data-product-qa");
          if (pr) {
            _qaFinishArr.push(pr);
            if (_qaFinishArr[i]) {
              var _toFinishArray = _qaFinishArr[i].split(',');
              var sumFinish = [];
              if (filterQA_Index.length > 1) {
                $.each(filterQA_Index, function (i, val) {
                  var _getmaxVal = Object.keys(_toFinishArray).map(function (key) {
                    return _toFinishArray[key];
                  });
                  _getmaxVal = _getmaxVal[val].substring(_getmaxVal[val].indexOf("=") + 1);
                  sumFinish.push(_getmaxVal);
                });
                var totFinish = 0;
                for (var i = 0; i < sumFinish.length; i++) {
                  totFinish += sumFinish[i] << 0;
                }
                _maxFinishArr.push(String(totFinish));
              }
              else {
                $.each(filterQA_Index, function (i, val) {
                  var _maxVal = Object.keys(_toFinishArray).map(function (key) {
                    return _toFinishArray[key];
                  });
                  _maxVal = _maxVal[val].substring(_maxVal[val].indexOf("=") + 1);
                  _maxFinishArr.push(_maxVal);
                });
              }
            }
          }
        });
        getProdMaxVal("finish", _maxFinishArr);


        function getProdMaxVal(_prodCat, _prodMaxValArr) {

          $("." + _prodCat + " .productListClass").hide();
          $("." + _prodCat + " .productListClass[data-product-qa]").hide();


          var getRankArr = [];
          var _getRankArr = [];
          var isChecked = true;
          $.each(_prodMaxValArr, function (key, value) {
            var firstMax = Math.max.apply(null, _prodMaxValArr);
            if (firstMax == value) {
              var getli_rank = $("." + _prodCat + " .productListClass[data-product-qa]:eq(" + key + ")").attr("data-ranking");
              getRankArr.push(getli_rank);
            }
          });
          getRankArr.sort(function (a, b) { return b - a; });
          getRankArr.splice(1);
          var getRankVal = Object.keys(getRankArr).map(function (key) {
            return getRankArr[key];
          });
          $("." + _prodCat + " .productListClass[data-ranking=" + getRankVal + "]").show();
          showProdCount++;


          if ((_prodCat != "finish" && _prodCat != "style") && isChecked == true) {
            isChecked = false;
            var _MaxValArr2 = [];
            _MaxValArr2 = [].concat.apply([], _prodMaxValArr);
            $.each(_MaxValArr2, function (key, value) {
              var filterRank = $("." + _prodCat + " .productListClass[data-product-qa]:eq(" + key + ")").attr("data-ranking");
              if (filterRank == getRankVal) {
                _MaxValArr2.splice(key, 1, "-1");
              }
            });

            $.each(_MaxValArr2, function (key, value) {
              var secMax = Math.max.apply(null, _MaxValArr2);
              if (secMax == value) {
                var _getli_rank = $("." + _prodCat + " .productListClass[data-product-qa]:eq(" + key + ")").attr("data-ranking");
                _getRankArr.push(_getli_rank);
              }
            });
            _getRankArr.sort(function (a, b) { return b - a; });
            _getRankArr.splice(1);

            var getRankValue = Object.keys(_getRankArr).map(function (key) {
              return _getRankArr[key];
            });
            $("." + _prodCat + " .productListClass[data-ranking=" + getRankValue + "]").show();
            showProdCount++;

          }
        }
        $(".filter-header-res span.prod_count").text(showProdCount);
        $(".filter-header").hide();
        $(".filter-header-res").show();
        $('.prod_count').hide();
        setTimeout(function () {
          $(".filter-header-res .g-Text .cmn-richtext span").html($(".filter-header-res .g-Text .cmn-richtext span").html().replace('#', $(".filter-header-res .prod_count").html()));
        }, 50);
      }
      else {
        $(".product_results .productListClass, .product_results2 .productListClass").show();
        $(".filter-header").show();
        $(".filter-header-res").hide();
        $('.prod_count').hide();
      }

    });
  };
});
$('.filter-close').click(function () {
  $('.hideCol').parent('div').fadeIn();
  $(".productFliter-col").show();
  $('.thankyoumsg').fadeOut();
  $('.alertmsg').fadeOut();
  $('#btn-resetall').click();
  $('#btn-back-support').remove();
  $('.g-Recommendation__numOfProd .filter-header-res').fadeOut();
});
$('.g-ProductRecommendation .g-RecommendationTabBtn ul li a').click(function () {
  $('.hideCol').parent('div').fadeOut();
  $('#btn-resetall').click();
});
$('.g-ProductRecommendation .g-RecommendationTabBtn ul li').click(function () {
  $(".productListClass[data-product-qa]").addClass('filterdataonly');
  $(".productListClass:not([class*='filterdataonly'])").addClass("attributeNot");
  $(".productFliter-col").hide();
  $('.g-FilterReset').click();
  if ($('.qa-sec').length == 1) {
    $("#btn-next").addClass("hideBtn");
    $('#finishBtn').removeClass("hideBtn");
  }
});
$('.filter-close').click(function () {
  $('.productListClass').removeClass("attributeNot");
});
$('.g-ProductRecommendation .g-RecommendationTabBtn ul li a').removeClass('is-link g-ListUnit l-ListUnit');

$(document).ready(function () {
  function add_missing_qa() {
    console.log = function () { };
    var all_prod_ids = [];
    $(".g-ProductRecommendation .filter-qa-cont div#filter-tab1 li input").each(function (i) {
      var prodId = $(this).attr('id');
      all_prod_ids.push(prodId);
    });

    $(".product_results .productListClass").each(function (i) {
      var item_qa_list = [];
      var this_li = $(this);
      var prod_qa = $(this).attr("data-product-qa");
      try {
        if (prod_qa.split(",").length != all_prod_ids.length) {
          $.each(prod_qa.split(","), function (index, value) {
            var qa = value.split("=")[0];
            item_qa_list.push(qa);
          });

          var prod_qa_value_arr = prod_qa.split(",");
          $.each(all_prod_ids, function (index, value) {
            if ($.inArray(value, item_qa_list) == -1) {
              var missing_value = value + "= -100";

              prod_qa_value_arr.splice(index, 0, missing_value);
            }
          });
          this_li.attr("data-product-qa", prod_qa_value_arr.join());
        }
      }
      catch (e) {
      }
    });
  }
  $('.g-ProductRecommendation .g-RecommendationTabBtn ul li a').click(function () {
    add_missing_qa();
  });
});