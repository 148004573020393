$(document).ready(function() {
    var textSwiperPack = $(".js-TextCarouselP");

    var initializeSwiper = function(ele) {
        var textSwiperPackListElement = ele.find(".g-TextCarouselP__list");
        if (textSwiperPackListElement.length > 0) {
            var textSwiperPackAutoplay = ele.data("autoplay");
            var textSwiperPackAutoplayspeed = ele.data("autoplayspeed");
            var textSwiperPackTransitionEffect = ele.data("fade");

            if (!$.fn.isAuthorring()) {
                if (textSwiperPackAutoplay) {
                    textSwiperPackListElement
                        .slick({
                            autoplay: true,
                            autoplaySpeed: textSwiperPackAutoplayspeed * 1000,
                            fade: textSwiperPackTransitionEffect
                        });
                } else {
                    textSwiperPackListElement.slick({
                        fade: textSwiperPackTransitionEffect
                    });
                }
            }
        }
    }

    textSwiperPack.each(function(num, ele) {
        var $this = $(ele);
        initializeSwiper($this);
    });
});