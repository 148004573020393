$(document).ready(function(){
	var error = "<span class='booking-error' style='color: #cc2d30;'>"+$(".g-ButtonUnit__link.l-ButtonUnit__link").siblings("input[type='hidden']").val()+"</span>";
  var seminarLocation = "";      
  $(".g-SeminarAppointmentBooking__btn a.g-ButtonUnit__link").click(function(e){
        	e.preventDefault();
        	var href = $(this).attr("href");
            if($(".g-SeminarAppointmentList__item input[type=radio]:checked").length != 0){
                var seminarSessionObj = {
                    "seminarHeading" : $(".g-SeminarHeading input").val(),
                    "seminarLocation" : seminarLocation,
                    "seminarDate" : $(".g-SeminarAppointmentList__item input[type=radio]:checked").val()
                };
                sessionStorage.clear();
                var seminarSessionKey = "seminar" + (Math.random() * 10000000000).toFixed(0);
                sessionStorage.setItem(seminarSessionKey, JSON.stringify(seminarSessionObj));
                if(href.indexOf("?") == -1){
                	href = href + "?appointmentKey=" + seminarSessionKey;
                }
                else{
                	href = href + "&appointmentKey=" + seminarSessionKey;
                }
                window.location.href = href;
            }else{
            	if($(".booking-error").length == 0){
            		$(error).insertAfter(".g-SeminarAppointmentBooking__btn");
            	}
            }
        });
        
        $(".g-SeminarAppointmentList__item input[type=radio]").change(function(){
        	if($(this).is(":checked")){
              seminarLocation = $(this).closest(".g-SeminarAppointmentList").prev(".g-SeminarAppointmentLocation").find("h4").text();
        		$(".booking-error").hide();
        	}
        });
    });