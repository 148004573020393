$(document).ready(function() {
    var productDetailsElement = $(".g-ProductDetail");
    if (productDetailsElement.length > 0) {
        var imageCarousel = $(".js-ProductImageCarouselP .g-ProductImageCarouselP__list");
        var productSizeList = $(".g-ProductSize__list");
        var whereToBuy = $(".g-ProductDetail .ps-widget");

        var carouselUnit = $(".js-ProductImageCarouselP .g-ProductImageCarouselPUnit");
        var initialIdx = 0;

        if (carouselUnit.length > 0) {
            var defaultCarouselImg = $(carouselUnit[0]).data(
                "target").trim();
            carouselUnit.each(function(idx, ele) {
                var currentTarget = $(ele).data("target").trim();
                if (currentTarget === "default") {
                    initialIdx = idx;
                    defaultCarouselImg = currentTarget;
                    return false;
                }
            });
        }

        if (imageCarousel.length > 0) {

            var imageCarouselTransitionEffect = $(
                ".js-ProductImageCarouselP").data("fade");

            if (!$.fn.isAuthorring()) {
                imageCarousel.slick({
                    dots: true,
                    autoplay: false,
                    fade: imageCarouselTransitionEffect,
                    initialSlide: initialIdx
                });
            }

            if (productSizeList.length > 0) {

                var defaultLiItem = productSizeList.find("." +
                    defaultCarouselImg);

                productSizeList.click(function() {
                    $(this).find(".g-ProductSize__list__ul")
                        .toggleClass("open");
                });

                var changeInDropdown = function(option) {
                    productSizeList.find(".g-SelectedItem").text(
                        option.text().trim());
                }

                changeInDropdown($(defaultLiItem));

                var productSizeListItem = $(".g-ProductSize__list__item");

                productSizeListItem
                    .click(function() {
                        var _this = $(this);
                        var dataTarget = _this.data("target");
                        var currentListItem = dataTarget.trim() ||
                            dataTarget;
                        changeInDropdown(_this);
                        carouselUnit
                            .each(function(num, ele) {
                                if (currentListItem != undefined &&
                                    $(ele)
                                    .hasClass(
                                        currentListItem)) {
                                    imageCarousel.slick(
                                        "slickGoTo",
                                        num);
                                }
                            });
                            whereToBuy
                            .attr(
                                "ps-sku",
                                _this
                                .data("sku"));
                        if (typeof PriceSpider !== "undefined") {
                            PriceSpider
                                .rebind();
                        }
                    });

                imageCarousel
                    .on(
                        'afterChange',
                        function(event, slick, currentSlide) {
                            var dataTarget = $(
                                carouselUnit[currentSlide])
                            .data(
                                "target");
                            productSizeListItem
                                .each(function(num, ele) {
                                    if (dataTarget != undefined) {
                                        if ($(ele)
                                            .hasClass(
                                                dataTarget
                                                .trim())) {
                                            changeInDropdown($(ele));
                                        }
                                    }
                                });

                                var correspondingListItem = productSizeList
                                                .find("." +
                                                    dataTarget
                                                    .trim());
                                            whereToBuy
                                                .attr(
                                                    "ps-sku",
                                                    correspondingListItem
                                                    .data("sku"));
                                            if (typeof PriceSpider !== "undefined") {
                                                PriceSpider
                                                    .rebind();
                                            }

                        });

            }
        }

        // show online stores popup
        var prdouctOnlineStoresBtn = $(".g-ProductOnlineStores .online-stores-button");
        var prdouctOnlineStoresModal = $(".g-ProductOnlineStores .g-ProductOnlineStoresModal");
        var onlineStores = $(".g-ProductOnlineStores .g-ProductOnlineStores__list__item a");

        if (onlineStores.length > 0) {
            onlineStores.each(function(num, ele) {
                var bg = $(ele).data("bgimg-org");
                $(ele).css({
                    "background-image": "url(" + bg + ")"
                });
            });
        }

        prdouctOnlineStoresBtn.click(function() {
            $(".g-ProductOnlineStoresModal").show();
        });

        prdouctOnlineStoresModal.click(function(e) {
            if (e.target == this) {
                $(this).hide();
            }
            return;
        });

        $(".g-ProductOnlineStoresModal").click(function() {
            prdouctOnlineStoresModal.hide();
        });

        // add schema.org attribute to product name
        $("div.g-ProductTitle h1.g-PageTitle__h1").attr("itemprop",
            "name");

        $(document)
            .click(
                function(e) {
                    if (!$(e.target).hasClass(
                            "g-ProductSize__list")) {
                        $(
                                ".g-ProductSize__list .g-ProductSize__list__ul")
                            .removeClass("open");
                    }
                })

    }

});