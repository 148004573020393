$(document).ready(function () {
	var glossaryItemElement = $('.g-GlossaryItem');
	if (glossaryItemElement.length > 0 && !$.fn.isAuthorring()) {
		var pageUrl = window.location.href;
		var pageUrlParts = [];
		var key = '';
		var fromClick = false;

		$('.hash').attr('id', 'hash');
		$(window).width() < 1025 ? $('.g-GlossaryContent').show() : $('.g-GlossaryContent').hide();

		if ($(window).width() < 1025) {
			var _this = $('.g-GlossaryFilter .g-GlossaryFilter__item');
			var heightCont = ($(window).height() - 76) / 27;
			_this.height(heightCont - 3);
			_this.css('line-height', heightCont);
		}

		$(window).on('load', function () {
			if (pageUrl.includes('#')) {
				pageUrlParts = pageUrl.split('#');
				if (pageUrlParts[1].includes('/')) {
					var tempPageUrlParts = pageUrlParts[1].split('/');
					key = tempPageUrlParts[0];
					ingredientId = tempPageUrlParts[1];
				} else {
					key = pageUrlParts[pageUrlParts.length - 1];
				}
			} else {
				key = 'a';
			}
			if (key != ' ') {
				getApiUrl(pageUrl);
			}
		});

		function getApiUrl(pageUrl) {
			$('.g-GlossaryFilter .g-GlossaryFilter__item').removeClass('active');
			key = getKey(pageUrl);
			if (key === 'hash') {
				$(".g-GlossaryFilter__item[data-filter='#']").addClass('active');
			} else if (key != '') {
				$('.g-GlossaryFilter__item[data-filter=' + key + ']').addClass('active');
			}

			if ($(window).width() < 1025) {
				pageUrl = window.location.href;
				if (key !== 'a') {
					scrollToIngredientSection(getKey(pageUrl));
				}

				$(window).on('scroll', function () {
					if (!fromClick) {
						$('.g-GlossaryContent .glossary-letter').each(function () {
							var thisTop = $(this).offset().top - $(window).scrollTop();
							if (thisTop > 24 && thisTop < 200) {
								var id = $(this).attr('id');
								if (id === 'hash') {
									id = '#';
								}
								$('.g-GlossaryFilter .g-GlossaryFilter__item').removeClass('active');
								$('.g-GlossaryFilter .g-GlossaryFilter__item[data-filter=' + id + ']').addClass('active');
							}
						});
						filterPosition();
					}
				});
			}

			GlossaryContent();
		}

		function getKey(pageUrl) {
			if (pageUrl.includes('#')) {
				pageUrlParts = pageUrl.split('#');
				if (pageUrlParts[1].includes('/')) {
					var tempPageUrlParts = pageUrlParts[1].split('/');
					key = tempPageUrlParts[0];
					ingredientId = tempPageUrlParts[1];
				} else {
					key = pageUrlParts[pageUrlParts.length - 1];
				}
			} else {
				key = 'a';
			}
			return key;
		}

		function GlossaryContent() {
			if ($(window).width() > 1025) {
				$('.g-GlossaryContent').show();
				var id = $('.g-GlossaryFilter__item.active').attr('data-filter');
				if (id === '#') {
					id = 'hash';
				}
				$('.glossary-letter ').each(function () {
					if (id === this.id) {
						$(this).css('display', 'block');
					} else {
						$(this).css('display', 'none');
					}
				});
				// $(window).scrollTop(0);
			}
		}
		$('.g-GlossaryFilter__item').click(function () {
			$('.glossary-details').hide();
			$('.glossary-subwrap').removeClass('active');

			$('.g-GlossaryFilter__item').removeClass('active');
			key = $(this).children().text().toLowerCase();
			if (key === '#') {
				key = 'hash';
			}

			if ($(window).width() < 1025) {
				scrollToIngredientSection(key);
			}
			pageUrlParts = pageUrl.split('#');
			window.location.href = pageUrlParts[0] + '#' + key;
			if ($(window).width() > 1024) {
				$(this).addClass('active');
				$('.glossary-letter ').each(function () {
					var id = $('.g-GlossaryFilter__item.active').attr('data-filter');
					if (id === this.id) {
						$(this).css('display', 'block');
					} else {
						$(this).css('display', 'none');
					}
					// $(window).scrollTop(0);
				});
			}
		});

		$(window).on('hashchange', function () {
			$('.glossary-details').hide();
			$('.glossary-subwrap').removeClass('active');
			pageUrl = window.location.href;
			$('.g-GlossaryFilter .g-GlossaryFilter__item').removeClass('active');
			key = getKey(pageUrl);

			if (key === 'hash') {
				$(".g-GlossaryFilter__item[data-filter='#']").addClass('active');
			} else if (key != '') {
				$('.g-GlossaryFilter__item[data-filter=' + key + ']').addClass('active');
			}

			if ($(window).width() > 1024) {
				$('.glossary-letter ').each(function () {
					if (key === this.id) {
						$(this).css('display', 'block');
					} else {
						$(this).css('display', 'none');
					}
					// $(window).scrollTop(0);
				});
			} else {
				scrollToIngredientSection(key);
			}
		});

		$(document).on('click', '.glossary-name', function () {
			if ($(this).parent().hasClass('active')) {
				$('.glossary-subwrap').removeClass('active');
				$(this).siblings('.glossary-details').slideUp(200);
				if ($(window).width() < 1025) {
					$('body').css('overflow', 'hidden');
				}
			} else {
				$('.glossary-details').slideUp(400);
				$('.glossary-subwrap').removeClass('active');
				$(this).siblings('.glossary-details').slideToggle(200);
				$(this).parent().addClass('active');
				if ($(window).width() < 1025) {
					$('body').css('overflow', 'hidden');
				}
			}
		});

		$(document).on('click', '.glossary-subwrap .close_btn', function () {
			$(this).closest('.glossary-subwrap').removeClass('active');
			$(this).closest('.glossary-details').slideUp(200);
			if ($(window).width() < 1025) {
				$('body').css('overflow', 'auto');
			}
		});

		ingredient(pageUrl);
		function ingredient(pageUrl) {
			if (pageUrl.includes('#')) {
				pageUrlParts = pageUrl.split('#');
				if (pageUrlParts[1].includes('/')) {
					var tempPageUrlParts = pageUrlParts[1].split('/');
					key = tempPageUrlParts[0];
					var ingredientId = tempPageUrlParts[1].toLowerCase();
					if (ingredientId.includes('%20')) {
						ingredientId = ingredientId.replace('%20', '_');
					} else if (ingredientId.includes('-')) {
						ingredientId = ingredientId.replace('-', '_');
					}
				}
			}
			$('.glossary-subwrap').each(function () {
				var attr = $(this).attr('data-ingredientname');
				if (ingredientId === attr) {
					$(this).addClass('active');
					$($(this)).children('.glossary-details').css('display', 'block');
				}
			});
		}

		function scrollToIngredientSection(key) {
			var scrollFilter = key;
			if (scrollFilter === '#') {
				scrollFilter = 'hash';
			}
			if (key != '') {
				$('html, body').animate(
					{
						scrollTop: $('#' + scrollFilter).offset().top - 75,
					},
					2000,
					function () {
						fromClick = false;
					}
				);
			}
		}

		function filterPosition() {
			var glossaryTop = $('.g-GlossaryItem').offset().top - $(window).scrollTop();

			if (glossaryTop < 200) {
				$('.g-GlossaryFilter').css({
					position: 'fixed',
					top: '75px',
					right: '2%',
				});
			} else {
				$('.g-GlossaryFilter').css({
					position: 'absolute',
					top: 30,
					right: 0,
				});
			}
		}
		if ($(window).width() < 1024) {
			filterPosition();
		}
	}
});