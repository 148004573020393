$(document).ready(function() {
    var filterOptions = $('.g-FilterCategoryList__item input');
    $(window).on('load', function() {
        $('input:checkbox').prop('checked', false);
    });
    $(window).resize(function() {
        if ($(window).width() <= 767) {
            $('.g-FilterContainerVertical .g-FilterCategoryTitle').click(function() {
                $(this).siblings('.g-FilterCategoryList').toggleClass('open');
            });
        }
    });

    $.fn.productFilter = function() {
        var andGroupFilters = [];
        var orGroupFilters = [];
        var andArray = [];
        var orArray = [];
        var filterBody = $('.g-FilterContainerHorizontal .g-FilterBody');
        var filterHead = $('.g-FilterContainerHorizontal .g-FilterHead');
        var tagContainer = $('.g-FilterContainerHorizontal .js-TagContainer');
        var noResult = $('p.g-Filter__noResult');
        var tagCounter = 1;
        var isNewsIndex = false;
        var productUnitsSelector = [];
        var productUnits = [];
        var noResultsContainer = [];
        var productList = [];

        var compactFilterLabel = $('.g-FilterContainerVerticalCompact');
        var arrow = '<span class="arrow"></span>';
        var compactFilterTitle = $('.g-FilterContainerVerticalCompact .g-FilterCategoryTitle');
        var productTag = $('.g-FilterContainerVerticalCompact .g-TagContainer');
        var productListing = $('.g-ProductListingListUnit');
        var totalProducts = $('.noOfProducts__message');

        // initialize selectors or elements based upon the type of product container
        // supports: news index card item containers (or) product listing containers
        if ($('.g-ProductListing').length > 0) {
            productUnitsSelector = '.g-ProductListingListUnit';
            productUnits = $('.g-ProductListingListUnit');
            noResultsContainer = $('.g-ProductListing--inner');
            productList = $('.g-ProductListingList--inner');
            isNewsIndex = false;
        } else if ($('.g-NewsIndexP--v3.is-cardview').length > 0) {
            productUnitsSelector = '.g-NewsIndexP--v3__card__item';
            productUnits = $('.g-NewsIndexP--v3__card__item');
            noResultsContainer = $('.g-NewsIndexP--v3');
            productList = $('.g-NewsIndexP--v3__card');
            isNewsIndex = true;
        } else {
            return;
        }

        noResultsContainer.each(function(num, ele) {
            $(noResult).clone().appendTo(ele);
        });

        $('.g-FilterReset').click(function() {
            filterOptions.prop('checked', false);
            tagContainer.find('.g-Tag').remove();
            tagCounter = 1;
            andGroupFilters = [];
            orGroupFilters = [];
            andArray = [];
            orArray = [];
            displayAllProducts();
            noResult.hide();
            numberUpdate(numstr);
            NumberOftagSelected()
        });

        $('.g-FilterContainerHorizontal .js-Filter').click(function() {
            filterBody.slideToggle(200);
            filterHead.toggleClass('closed');
        });

        $('.g-FilterReset').click(function() {
            filterOptions.prop('checked', false);
            tagContainer.find('.g-Tag').remove();
            compactFilterLabel.find('.g-FilterCategoryList__item').removeClass('active');
            compactFilterLabel.find('.g-FilterCategoryList__item  span').removeClass('active');
            compactFilterLabel.closest('.verticalCompact').find('.g-Filter__taglist .active').remove();
            tagCounter = 1;
            newTitleHeight();
        });

        $('.g-FilterContainerHorizontal .js-FilterClose').click(function() {
            filterBody.slideUp(200);
            filterHead.toggleClass('closed');
        });

        var generateTag = function(input) {
            input.attr('data-tag', tagCounter);
            var tag = '<span class="g-Tag l-Tag"><span class="g-ClearTag l-ClearTag js-clearTag" data-tag="' + tagCounter + '"></span>' + input.closest('label').text().trim() + '</span>';
            tagContainer = $('.g-Filter__tagList');
            tagContainer.append(tag);
            tagCounter++;
            visibility(productListing);
        };

        var deleteTag = function(tag, isFromCheckbox) {
            var tagToDelete = $(tag);
            var correspondingInput = $("input[data-tag='" + tagToDelete.attr('data-tag') + "']");
            tagToDelete.parent().remove();
            correspondingInput.prop('checked', false);
            correspondingInput.removeAttr('data-tag');
            if (!isFromCheckbox) {
                correspondingInput.trigger('change');
            }
        };

        $(document).on('click', '.js-clearTag .active', function() {
            deleteTag(this, false);
        });

        var createProductList = function(selectedInputObj, currentLogicArray, otherLogicArray, callback) {
            var negateLogic = selectedInputObj.logic == 'and' ? 'or' : 'and';
            var currentLogicResult = [];
            var recurrsive = true;

            var finalCategoryArray = function(logicArray, currentLogic, bothSet_Callback) {
                //bothSet_Callback is called only when both group are set
                var productList = new Array();
                var resultProductList = new Array();
                logicArray.forEach(function(item, num) {
                    var temp = new Array();
                    for (var i in item) {
                        productUnits.each(function(num, ele) {
                            var currentProduct = $(ele);
                            var productTags = currentProduct.data('tags');
                            var productFilters;
                            var dataId = currentProduct.data('id');
                            if (productTags) {
                                productFilters = productTags.split(',');
                            } else {
                                productFilters = [];
                            }
                            for (var j = 0; j < productFilters.length; j++) {
                                if (item[i].indexOf(productFilters[j]) != -1) {
                                    if (productList.indexOf(dataId) == -1) {
                                        productList.push(dataId);
                                    } else {
                                        continue;
                                    }
                                }
                            }
                        });

                        if (resultProductList.length == 0 && num == 0) {
                            resultProductList = productList.slice(0);
                            productList = [];
                        } else if (currentLogic == 'or') {
                            for (var m = 0; m < productList.length; m++) {
                                if (resultProductList.indexOf(productList[m]) == -1) {
                                    resultProductList.push(productList[m]);
                                }
                            }
                        } else if (currentLogic == 'and') {
                            for (var k = 0; k < productList.length; k++) {
                                if (resultProductList.indexOf(productList[k]) != -1) {
                                    temp.push(productList[k]);
                                }
                            }
                            resultProductList = temp.slice(0);
                            productList = [];
                            temp = [];
                        }
                    }
                });

                if (!selectedInputObj.bothSet) {
                    callback(resultProductList);
                    return;
                } else if (selectedInputObj.bothSet && recurrsive) {
                    recurrsive = false;
                    currentLogicResult = resultProductList.slice(0);
                    finalCategoryArray(otherLogicArray, negateLogic, bothSet);
                } else if (selectedInputObj.bothSet && !recurrsive) {
                    bothSet(currentLogicResult, resultProductList);
                }
            };

            var bothSet = function(array1, array2) {
                for (var i = 0; i < array1.length; i++) {
                    var array2Item = array2.indexOf(array1[i]);
                    if (array2Item != -1) {
                        array2.splice(array2Item, 1);
                    }
                }
                callback(array1.concat(array2));
                return;
            };
            finalCategoryArray(currentLogicArray.length > 0 ? currentLogicArray : otherLogicArray, currentLogicArray.length > 0 ? selectedInputObj.logic : negateLogic);
        };

        var arrayUpdator = function(selectedInputObj, currentLogicFilterArray, currentLogicArray, otherLogicArray, callback) {
            var parentPathObj = {};
            var isParentPathAvailable = false;

            if (currentLogicFilterArray.indexOf(selectedInputObj.currentFilterPath) == -1 && selectedInputObj.inputState) {
                currentLogicFilterArray.push(selectedInputObj.currentFilterPath);

                parentPathObj[selectedInputObj.parentPath] = [];
                parentPathObj[selectedInputObj.parentPath].push(selectedInputObj.currentFilterPath);

                if (currentLogicArray.length == 0) {
                    currentLogicArray.push(parentPathObj);
                } else {
                    for (var j = 0; j < currentLogicArray.length; j++) {
                        if (currentLogicArray[j].hasOwnProperty(selectedInputObj.parentPath)) {
                            currentLogicArray[j][selectedInputObj.parentPath].push(selectedInputObj.currentFilterPath);
                            isParentPathAvailable = true;
                            break;
                        }
                    }
                    if (!isParentPathAvailable) {
                        currentLogicArray.push(parentPathObj);
                    }
                }
            } else if (!selectedInputObj.inputState) {
                var idxToRemove = null;
                var idx = currentLogicFilterArray.indexOf(selectedInputObj.currentFilterPath);
                currentLogicFilterArray.splice(idx, 1);

                for (var i = 0; i < currentLogicArray.length; i++) {
                    if (currentLogicArray[i].hasOwnProperty(selectedInputObj.parentPath)) {
                        var filterIdx = currentLogicArray[i][selectedInputObj.parentPath].indexOf(selectedInputObj.currentFilterPath);
                        currentLogicArray[i][selectedInputObj.parentPath].splice(filterIdx, 1);

                        if (currentLogicArray[i][selectedInputObj.parentPath].length == 0) {
                            idxToRemove = i;
                        }
                    }
                }
                if (idxToRemove != null) {
                    currentLogicArray.splice(idxToRemove, 1);
                }
            }

            if (andGroupFilters.length > 0 && orGroupFilters.length > 0) {
                selectedInputObj.bothSet = true;
            } else {
                selectedInputObj.bothSet = false;
            }

            if (currentLogicArray.length > 0 || otherLogicArray.length > 0) {
                callback(selectedInputObj, currentLogicArray, otherLogicArray, productsToDisplay);
            } else {
                displayAllProducts();
            }
        };

        var productsToDisplay = function(dataIdArray) {
            productUnits.removeClass('show');
            for (var i = 0; i < dataIdArray.length; i++) {
                $(productUnitsSelector + "[data-id='" + dataIdArray[i] + "']").addClass('show');
            }
            productUnits.height('auto');
            if (dataIdArray.length == 0) {
                $('.g-ProductListing--inner .g-Filter__noResult').show();

                productList.isotope({
                    filter: '.show',
                    layoutMode: 'fitRows',
                });
            } else {
                $('	.g-Filter__noResult').hide();
                if (!isNewsIndex) {
                    equalHeightForResults(dataIdArray);
                } else {
                    return productList.isotope({
                        filter: '.show',
                        layoutMode: 'fitRows',
                    });
                }
            }
        };

        var displayAllProducts = function() {
            productUnits.addClass('show');
            productList.isotope('destroy');
            $('.g-ProductListing--inner .g-Filter__noResult').hide();
        };

        var equalHeightForResults = function(filterResult) {
            var targetClass = '';
            var windowWidth = $(window).width();
            if (windowWidth > 1024) {
                targetClass = 'g-Column__cols-lg';
            } else if (windowWidth > 640 && windowWidth < 1025) {
                targetClass = 'g-Column__cols-md';
            } else {
                targetClass = 'g-Column__cols-sm';
            }

            var setHeight = function(itemsPerRow, items, currentProductListing) {
                var elementsArray = [];
                var heightToSet;
                if (items.length == 0) {
                    return currentProductListing.find('.g-Filter__noResult').show();
                } else {
                    currentProductListing.find('.g-Filter__noResult').hide();
                }
                for (var i = 0; i < items.length;) {
                    elementsArray = [];
                    heightToSet = 0;
                    if (i < items.length - itemsPerRow) {
                        elementsArray = items.slice(i, i + itemsPerRow).map(function(id) {
                            var ele = $('.g-ProductListingListUnit' + "[data-id='" + id + "']");
                            heightToSet = heightToSet < ele.height() ? ele.height() : heightToSet;
                            return ele;
                        });

                        i = i + itemsPerRow;
                    } else {
                        elementsArray = items.slice(i, items.length).map(function(id) {
                            var ele = $('.g-ProductListingListUnit' + "[data-id='" + id + "']");
                            heightToSet = heightToSet < ele.height() ? ele.height() : heightToSet;
                            return ele;
                        });

                        i = items.length;
                    }

                    $.each(elementsArray, function(num, ele) {
                        ele.height(heightToSet);
                    });
                }
                return productList.isotope({
                    filter: '.show',
                    layoutMode: 'fitRows',
                });
            };

            $('.g-ProductListing').each(function(num, ele) {
                var currentListing = $(ele);
                var productListingID = currentListing.attr('id');
                var resultOfCurrentComponent = productListingID == undefined ? filterResult : [];
                var classList = currentListing.find('.g-ProductListingListUnit').eq(0).attr('class').split(/\s+/);
                var orderArray = [];

                if (productListingID) {
                    for (var j = 0; j < filterResult.length; j++) {
                        if (filterResult[j].indexOf(productListingID) != -1) {
                            var orderNum = filterResult[j].replace(productListingID + '_item', '');
                            orderArray.push(Number(orderNum));
                        }
                    }
                    orderArray = orderArray.sort(function(a, b) {
                        return a - b;
                    });

                    for (var k = 0; k < orderArray.length; k++) {
                        resultOfCurrentComponent.push(productListingID + '_item' + orderArray[k]);
                    }
                }

                for (var i = 0; i < classList.length; i++) {
                    if (classList[i].indexOf(targetClass) != -1) {
                        var numString = classList[i].slice(classList[i].length - 1, classList[i].length);
                        setHeight(parseInt(numString), resultOfCurrentComponent, currentListing);
                        break;
                    }
                }
            });
        };

        $(this).on('change', function(event) {
            var input = $(this);
            if (input.prop('checked')) {
                generateTag(input);
                NumberOftagSelected();
            } else {
                var tag = tagContainer.find("span[data-tag='" + input.attr('data-tag') + "']");
                deleteTag(tag, true);
                NumberOftagSelected();
            }
            var inputObj = {
                inputState: input.is(':checked'),
                currentCategory: input.closest('.g-FilterCategory'),
                logic: input.closest('.g-FilterCategory').data('filter-logic'),
                parentPath: input.closest('.g-FilterCategory').data('filter-parent-path'),
                currentFilterPath: input.closest('label').data('filter'),
                bothSet: false,
            };
            arrayUpdator(inputObj, inputObj.logic == 'and' ? andGroupFilters : orGroupFilters, inputObj.logic == 'and' ? andArray : orArray, inputObj.logic == 'and' ? orArray : andArray, createProductList);
            numberUpdate(numstr);
            newTitleHeight();
        });

        totalProducts.html(function(index, text) {
            return text.replace('#', '<span class="numstr">#</span>')
        });
        var numstr = $('.numstr')

        numstr.text(productListing.length)

        var visibility = function(productListing) {
            productListing.each(function() {
                if ($(this).hasClass('show')) {
                    $(this).css('opacity', 1);
                }
            });
        };

        var addArrow = function(arrowHolder) {
            arrowHolder.append(arrow);
        };
        var numberUpdate = function(totalProducts) {
            var totalItems = $('.show').length;
            totalProducts.text(totalItems);
        };

        var NumberOftagSelected = function(param) {
            var selected = $('input:checked').length;
            $('.g-FilterButton').text('Filter (' + selected + ')');

            if (selected < 1) {
                $('.g-FilterButton').text('Filter');
            }
        };

        compactFilterTitle.click(function() {
            $(this).toggleClass('active');
            $(this).siblings().toggle();
        });

        if (compactFilterLabel.length > 0) {
            addArrow(compactFilterTitle);
            $('#area-Contents').addClass('verticalCompact');
            $('.g-ProductListing--inner').prepend(productTag);
            $('.g-FilterOption').after('<span class="customcheckbox"></span>');

            if ($(window).width() <= 767) {
                $('.g-ProductListing--inner').prepend('<div class="g-Filterbtn l-Filterbtn"><button class="g-FilterButton l-FilterButton">Filter</Button></div>');
                $('.g-Filter__tagList').children().length > 0 ? $(productTag).css('min-height', '30px') : $(productTag).css('min-height', 0)

                var filterholder2 = $('.g-Filter__tagList').clone();
                $('.g-Filter').after(filterholder2);
                $(window).resize(function() {
                    $('.g-Filter').text('FILTER');

                    var left = compactFilterLabel.width();
                    compactFilterLabel.css('left', -left);

                    $('.close-btn').click(function() {
                        compactFilterLabel.animate({
                                left: -left,
                                opacity: 0,
                            },
                            200
                        );
                    });

                    $('.g-FilterButton').click(function() {
                        compactFilterLabel.animate({
                                left: 0,
                                opacity: 1,
                            },
                            200
                        );
                    });
                });
            }
        }
        var newTitleHeight = function() {
            var windowWidth = $(window).width()
            var n = 0;
            var heights = [];
            var tempArray = []
            var visibleItems = $('.g-ProductListingListUnit.show')
            var classList = $('.g-ProductListingListUnit').attr('class').split(' ');
            var itemsPerRow = Number((function() {
                if (windowWidth > 1024) {
                    for (var i = 0; i < classList.length; i++) {
                        if (classList[i].includes('g-Column__cols-lg-')) {
                            var items = classList[i].match(/\d/).toString()
                            return items;
                        }
                    }
                } else if (windowWidth < 1025 && windowWidth > 640) {
                    for (var i = 0; i < classList.length; i++) {
                        if (classList[i].includes('g-Column__cols-md-')) {
                            var items = classList[i].match(/\d/).toString()
                            return items;
                        }
                    }
                } else if (windowWidth < 641) {
                    for (var i = 0; i < classList.length; i++) {
                        if (classList[i].includes('g-Column__cols-sm-')) {
                            var items = classList[i].match(/\d/).toString()
                            return items;
                        }
                    }
                }
            })())
            for (var i = 0; i < visibleItems.length; i += itemsPerRow) {
                tempArray = visibleItems.slice(n, n + itemsPerRow)
                for (var j = 0; j < tempArray.length; j++) {
                    if (tempArray.hasClass('show')) {
                        var currentHeight = tempArray.eq(j).find('h2.g-ProductOverview__h2').css('height')
                        heights.push(parseInt(currentHeight))

                    }
                }
                heights.sort(function(a, b) {
                    return b - a;
                })
                if (heights.length > 1) {
                    tempArray.each(function() {
                        $(this).find('h2.g-ProductOverview__h2').css('height', heights[0])
                    })
                }
                heights = [];
                tempArray = [];
                n = n + itemsPerRow
            }
        }

        $(document).on('click', '.g-ClearTag', function() {
            var currentTag = $(this).attr('data-tag');
            $('.g-FilterCategoryList__item').each(function() {
                var requiredTag = $(this).find('input');
                if (currentTag === requiredTag.attr('data-tag')) {
                    var tag = tagContainer.find("span[data-tag='" + requiredTag.attr('data-tag') + "']");
                    deleteTag(tag, true);
                    NumberOftagSelected();

                    var inputObj = {
                        inputState: requiredTag.is(':checked'),
                        currentCategory: requiredTag.closest('.g-FilterCategory'),
                        logic: requiredTag.closest('.g-FilterCategory').data('filter-logic'),
                        parentPath: requiredTag.closest('.g-FilterCategory').data('filter-parent-path'),
                        currentFilterPath: requiredTag.closest('label').data('filter'),
                        bothSet: false,
                    };

                    arrayUpdator(inputObj, inputObj.logic == 'and' ? andGroupFilters : orGroupFilters, inputObj.logic == 'and' ? andArray : orArray, inputObj.logic == 'and' ? orArray : andArray, createProductList);
                    numberUpdate(numstr);
                }
                visibility(productListing);
            });
            newTitleHeight()
        });
    };

    filterOptions.productFilter();
});