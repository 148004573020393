$(document).ready(function(){

        var level = null;
        var headerMenuContainer = $(".g-HeaderMenuContainer__inner");

        if($(window).width() < 768 && !$.fn.isAuthorring()){
            $(".g-Header .js-HeaderMenuButton").click(function(){
                headerMenuContainer.toggle();            
            });

            $(".g-Header .g-HeaderMainNav__ul li > a, .g-Header .g-HeaderMainNav__ul li > span.g-HeaderMenu__noLink").click(function(e){

                var isSpan = $(this).tagName == "SPAN";

                var otherListItems = $(this).closest("li").siblings("li");
                level = levelFinder($(this));

                if(level != undefined && !$(this).parent().hasClass("active")){
                    e.preventDefault();
                    $(".js-HeaderMenuBackButton").show(); 

                    if(isSpan){
                        $(".active > span.g-HeaderMenu__noLink").hide().parent().removeClass("active").addClass("previously-active");
                    }
                    else{
                        $(".active > a.g-HeaderMenu__link").hide().parent().removeClass("active").addClass("previously-active");
                    }
                    headerMenuNavigatorForward($(this),otherListItems,level);
                }
            });

            $(".g-Header .js-HeaderMenuBackButton").click(function(){
                if($(".active > a.g-HeaderMenu__link").length != 0){
                    headerMenuNavigatorBackward($(this),$(".active > a.g-HeaderMenu__link"));
                }
                else{
                    headerMenuNavigatorBackward($(this),$(".active > span.g-HeaderMenu__noLink"));
                }
            });
        }

        var headerMenuNavigatorForward = function(currentAnchorEle, otherLiItems, levelContainer){
            otherLiItems.hide();
            currentAnchorEle.parent().addClass("active");
            levelContainer.show();
        }

        var headerMenuNavigatorBackward = function(backButton,activeAnchor){
            var previouslyActive = activeAnchor.closest("ul").closest("li.previously-active");

            levelFinder(activeAnchor).hide();

            activeAnchor.parent().removeClass("active");
            activeAnchor.closest("li").siblings("li").show();
            
            if(previouslyActive.length > 0){
                
                previouslyActive.removeClass("previously-active").addClass("active");
                
                previouslyActive.children("a.g-HeaderMenu__link").length > 0 ? previouslyActive.children("a.g-HeaderMenu__link").show() : previouslyActive.children("span.g-HeaderMenu__noLink").show();
                    
            }
            else{
                backButton.hide();
            }
            return;
        }

        var levelFinder = function(activeAnchor){
            if(activeAnchor.siblings("div.g-HeaderDropdownContainer").length > 0){
                return activeAnchor.siblings("div.g-HeaderDropdownContainer");
            }
            else if(activeAnchor.siblings("div.g-HeaderSubCategoryContainer").length > 0){
                return activeAnchor.siblings("div.g-HeaderSubCategoryContainer");
            }
            else if(activeAnchor.siblings("div.g-HeaderItemsContainer").length > 0){
                return activeAnchor.siblings("div.g-HeaderItemsContainer");
            }
        }

        });