$(document).ready(function() {
    $(".g-ProductListingListUnit").click(function() {
        if ($(this).hasClass('active')) {
            $('.g-ProductListingListUnit').removeClass('active');
            $(this).find('.g-ProductListingSubProducts').slideUp(200);
        } else {
            $('.g-ProductListingListUnit .g-ProductListingSubProducts').slideUp(200);
            $('.g-ProductListingListUnit').removeClass('active');
            $(this).find('.g-ProductListingSubProducts').slideToggle(200);
            $(this).addClass('active');
        }
    });
    
    if ($(".g-ProductListingListUnit.g-Column__cols").length > 1 && $(".g-ProductListingListUnit.g-Column__cols").find("[data-id='extra-tile']")) {
		var colbox = $('.g-ProductListingListUnit.g-Column__cols:nth-child(2)')[0].getBoundingClientRect().width;
		var addwidth6 = colbox * 6;
		var addwidth5 = colbox * 5;
		var addwidth4 = colbox * 4;
		var addwidth3 = colbox * 3;
		var addwidth2 = colbox * 2;
		var addwidth1 = colbox * 1;
		if ($(window).width() > 1024) {
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-6").css("width", addwidth6);
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-5").css("width", addwidth5);
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-4").css("width", addwidth4);
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-3").css("width", addwidth3);
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-2").css("width", addwidth2);
			$(".g-ProductListing").find(".g-Column__cols-Width-Factor-1").css("width", addwidth1);
		}
	}
});