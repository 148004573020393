$(document).ready(function() {
    var imageTextSwiper = $(".js-ImageTextCarouselP");

	var initializeSwiper = function(ele) {
        var imageTextSwiperList = ele.find(".g-ImageTextCarouselP__list");

        if (imageTextSwiperList.length > 0) {
            var imageTextSwiperPackAutoplay = ele.data("autoplay");
            var imageTextSwiperPackAutoplayspeed = ele.data("autoplayspeed");
            var imageTextSwiperPackTransitionEffect = ele.data("fade");

            if (!$.fn.isAuthorring()) {
                if (imageTextSwiperPackAutoplay) {
                    imageTextSwiperList
                        .slick({
                            autoplay: true,
                            autoplaySpeed: imageTextSwiperPackAutoplayspeed * 1000,
                            fade: imageTextSwiperPackTransitionEffect
                        });
                } else {
                    imageTextSwiperList.slick({
                        fade: imageTextSwiperPackTransitionEffect
                    });
                }
			}
		}
    }

    imageTextSwiper.each(function(num, ele) {
        var $this = $(ele);
        initializeSwiper($this);
    });
});