$(document).ready(function() {
    var productSwiper = $(".js-ProductSwiper");
    var initializeSwiper = function(ele) {
        var productSwiperListElement = ele.find(".g-ProductSwiper__list");
        if (productSwiperListElement.length > 0) {
            if (!$.fn.isAuthorring()) {
                productSwiperListElement.slick();
            }
        }
    }
    productSwiper.each(function(num, ele) {
        var $this = $(ele);
        initializeSwiper($this);
    });

});